import { useEffect, useState } from "react";
import {
  Box,
  Text,
  Icon,
  Button,
  List,
  ListItem,
  ListIcon,
  IconButton,
  Image,
  useMediaQuery,
  UnorderedList,
} from "@chakra-ui/react";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { PlanStyle } from "./Plan.style";
import { fetchSubscriptionPlansAction } from "../../Actions/B2CSubscription.action";
import {
  createB2CSubscriptionHandler,
  openRazorpayCheckout,
} from "../../Actions/B2CPayment.action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { saveUser } from "../../../../../Redux/User-slice/User-slice";
import Navbar from "../../../../../GlobalComponents/Navbar/Navbar";

declare global {
  interface Window {
    Razorpay: any;
  }
}

export interface SubscriptionDetailsInterface {
  subscription_id: string;
  plan_id: string;
  status:
  | "created"
  | "authenticated"
  | "active"
  | "halted"
  | "cancelled"
  | "completed"; // Assuming typical subscription statuses
  short_url: string;
  user_id: string;
  subscriber_id: string;
}

export interface SubscriptionPlanInterface {
  planName: string;
  description: string;
  planId: string;
  amount: string;
  period: "daily" | "weekly" | "monthly" | "yearly"; // Assuming period can have specific string values
  interval: number;
  active: boolean;
  createdAt: Date;
  updatedAt: Date;
}

const CustomCheckIcon = (props: any) => (
  <Icon viewBox="0 0 19 15" {...props}>
    <path
      width={"50.432px"}
      d="M18.4513 3.97426L14.1353 8.29094L10.035 12.3913L8.72551 13.7008C8.16398 14.2617 7.04212 14.2617 6.48118 13.7008L0.870055 8.08963C0.507449 7.72702 0.379014 7.13019 0.485346 6.60271C0.542694 6.31298 0.67113 6.04363 0.870055 5.8447L2.1795 4.53586C2.73983 3.97432 3.8623 3.97432 4.42383 4.53586L7.60365 7.71561L11.9693 3.34941L13.9418 1.37635L14.8976 0.421148C15.4591 -0.140383 16.5816 -0.140383 17.1419 0.421148L18.4513 1.72999C18.6043 1.88346 18.716 2.07826 18.7853 2.29206C18.9711 2.86196 18.8594 3.56626 18.4513 3.97426Z"
      fill="#2A94F4"
    />
  </Icon>
);

const PlanDetails = ({ setStep }: any) => {
  const [isMobile] = useMediaQuery("(max-width: 480px)");
  const [isTab] = useMediaQuery("(max-width: 768px)");
  const userData: any = useSelector((state: any) => state.user);
  const [plans, setPlans] = useState<SubscriptionPlanInterface[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlanInterface>();
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [benCount, setBenCount] = useState<number>(1);
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const incrementBenCount = () => {
    setBenCount((prevCount) => prevCount + 1);
  };

  const decreamentBenCount = () => {
    setBenCount((prevCount) => prevCount - 1);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let res = await fetchSubscriptionPlansAction();
        setPlans(res);
        setSelectedPlan(res[0]);
      } catch (error) {
        console.error("Error fetching subscription plans:", error);
      }
    };
    fetchData();
  }, []);

  const selectPlanHandler = (plan: SubscriptionPlanInterface) => {
    setSelectedPlan(plan);
  };

  const paymentResponseHandler = async (response: any) => {
    dispatch(saveUser({ ...userData, numberOfBenAllowed: benCount }))
    navigate(`/subscription/payment-confirmation/${response.razorpay_subscription_id}/${response.razorpay_payment_id}/${benCount}`)
  };

  const HandlePayment = async () => {
    setIsLoading(true)
    if (!selectedPlan) {
      alert("Please Select Plan");
      return;
    }
    let subscriptionData: SubscriptionDetailsInterface =
      await createB2CSubscriptionHandler({
        planId: selectedPlan.planId,
        quantity: benCount,
      });
    if (!subscriptionData) {
      return;
    }

    const options = {
      key: "rzp_test_I4S0hxzvPnFLDQ",
      name: "Pococare",
      // image: 'https://your-logo-url.com/logo.png',
      subscription_id: `${subscriptionData.subscription_id}`,
      handler: paymentResponseHandler,
      prefill: {
        name: userData.name,
        email: userData.email,
        contact: userData.mobileNo.split('-')[1],
      },
      notes: { order_by: "subscription", ref_id: subscriptionData.subscription_id },
      theme: {
        color: "#F37254",
      },
    };

    openRazorpayCheckout(options);
    setIsLoading(false)
  };

  return (
    <>
    <Navbar/>
    <Box 
      mt={isMobile ? "80px" : isTab ? "100px" :"120px"} 
      bgGradient="linear(180deg, #FFF 0%, #D2F0F2 100%)"
      minHeight={"100vh"} 
      overflow={"hidden"}>
        <Box
          display={"flex"}
          flexDir={isMobile ? "column" : isTab ? "column" : "row"}
          justifyContent={"center"}
          gap={"23px"}
          p={isMobile ? "10px" : "20px"}
          backgroundImage="url('https://developmentbucket.blob.core.windows.net/svgs/image_prev_ui.svg')"
          backgroundSize="cover"         
          backgroundPosition="center"    
          backgroundRepeat="no-repeat"   
          borderRadius="md"
          width="full"
          mx="auto"
          margin={"auto"}
        >
        {plans.map((val: any) => (
          <Box position={
            "relative"
          }>
          <Box
            boxShadow="sm"
            p= {isMobile ? "10px 14px": "24px 32px"}
            border = {"1px ridge #0000001A"}
            onClick={() => selectPlanHandler(val)}
            borderRadius={"4px"}
            bgGradient="linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(240, 56, 156, 0.4) 100%)"
            position={"relative"}
            zIndex={9}

          >
            <Text
              color="#333"
              fontFamily="Poppins"
              fontSize={isMobile ?  "16" : "22px"}
              fontWeight="500"
              lineHeight="36px"   
              letterSpacing="0.06em"
              mb={isMobile ? "":"4"}
            >
              Plan Details
            </Text>
            <List spacing={isMobile ? 1:2}>
              <ListItem display={"flex"} flexDir={"row"} alignItems={"center"}>
                <ListIcon
                  as={CustomCheckIcon}
                  height={isMobile ? "9.35px":"14.12px"}
                  bg="#CCE9FF"
                  width={isMobile ? "12.2px":"18.42px"}
                  color="teal.500"
                  marginRight={isMobile ? "5px":"23.13px"}
                />
                <Text fontFamily="Poppins" fontSize={isMobile ? "12px":"14px"} lineHeight={"28px"} fontWeight={"500"} color={"#333333"} letterSpacing={"0.06em"}>
                  {" "}
                  Readiness: Building Your Safety Net
                </Text>
              </ListItem>
              <UnorderedList paddingLeft={isMobile ? "10px":"40px"}>
                <ListItem fontFamily={"Poppins"} fontSize={isMobile ? "8px":"10px"} fontWeight={400} letterSpacing={"0.06em"}>Personalized Emergency Response Plan</ListItem>
                <ListItem fontFamily={"Poppins"} fontSize={isMobile ? "8px":"10px"} fontWeight={400} letterSpacing={"0.06em"}>24/7 Access to Medical Experts (including virtual doctor consultations for non-emergency situations)</ListItem>
                <ListItem fontFamily={"Poppins"} fontSize={isMobile ? "8px":"10px"} fontWeight={400} letterSpacing={"0.06em"}>Family Member Inclusion</ListItem>
                <ListItem fontFamily={"Poppins"} fontSize={isMobile ? "8px":"10px"} fontWeight={400} letterSpacing={"0.06em"}>Emergency Contact Management</ListItem>
              </UnorderedList>
              <ListItem display={"flex"} flexDir={"row"} alignItems={"center"}>
                <ListIcon
                  as={CustomCheckIcon}
                  height={isMobile ? "9.35px":"14.12px"}
                  bg="#CCE9FF"
                  width={isMobile ? "12.2px":"18.42px"}
                  color="teal.500"
                  marginRight={isMobile ? "5px":"23.13px"}
                />
                <Text fontFamily="Poppins" fontSize={"14px"} lineHeight={"28px"} fontWeight={"500"} color={"#333333"} letterSpacing={"0.06em"}>
                  {" "}
                  Personalization
                </Text>
              </ListItem>
              <UnorderedList paddingLeft={isMobile ? "10px":"40px"}>
                <ListItem fontFamily={"Poppins"} fontSize={isMobile ? "8px":"10px"} fontWeight={400} letterSpacing={"0.06em"}>Ongoing Medical Profile Updates </ListItem>
                <ListItem fontFamily={"Poppins"} fontSize={isMobile ? "8px":"10px"} fontWeight={400} letterSpacing={"0.06em"}>Location-Based Services </ListItem>
                <ListItem fontFamily={"Poppins"} fontSize={isMobile ? "8px":"10px"} fontWeight={400} letterSpacing={"0.06em"}>Preference-Driven Communication </ListItem>
                <ListItem fontFamily={"Poppins"} fontSize={isMobile ? "8px":"10px"} fontWeight={400} letterSpacing={"0.06em"}>Customized Alerts & Reminders </ListItem>
              </UnorderedList>
              <ListItem display={"flex"} flexDir={"row"} alignItems={"center"}>
                <ListIcon
                 as={CustomCheckIcon}
                 height={isMobile ? "9.35px":"14.12px"}
                 bg="#CCE9FF"
                 width={isMobile ? "12.2px":"18.42px"}
                 color="teal.500"
                 marginRight={isMobile ? "5px":"23.13px"}
                />
                <Text fontFamily="Poppins" fontSize={"14px"} lineHeight={"28px"} fontWeight={"500"} color={"#333333"} letterSpacing={"0.06em"}>
                  {" "}
                  Response
                </Text>
              </ListItem>
              <UnorderedList paddingLeft={isMobile ? "10px":"40px"}>
                <ListItem fontFamily={"Poppins"} fontSize={isMobile ? "8px":"10px"} fontWeight={400} letterSpacing={"0.06em"}>Immediate Emergency Assistance </ListItem>
                <ListItem fontFamily={"Poppins"} fontSize={isMobile ? "8px":"10px"} letterSpacing={"0.06em"}>Real-Time Tracking & Coordination with Ambulance </ListItem>
                <ListItem fontFamily={"Poppins"} fontSize={isMobile ? "8px":"10px"} letterSpacing={"0.06em"}>Hospital Admission Facilitation </ListItem>
                <ListItem fontFamily={"Poppins"} fontSize={isMobile ? "8px":"10px"} letterSpacing={"0.06em"}>Doctor Consultation in an Emergency </ListItem>
              </UnorderedList>
              <ListItem display={"flex"} flexDir={"row"} alignItems={"center"}>
                <ListIcon
                  as={CustomCheckIcon}
                  height={isMobile ? "9.35px":"14.12px"}
                  bg="#CCE9FF"
                  width={isMobile ? "12.2px":"18.42px"}
                  color="teal.500"
                  marginRight={isMobile ? "5px":"23.13px"}
                />
                <Text fontFamily="Poppins" fontSize={"14px"} lineHeight={"28px"} fontWeight={"500"} color={"#333333"} letterSpacing={"0.06em"}>
                  {" "}
                  Pay Per Use 
                </Text>
              </ListItem>
              <UnorderedList paddingLeft={isMobile ? "10px":"40px"}>
                <ListItem fontFamily={"Poppins"} fontSize={isMobile ? "8px":"10px"} fontWeight={400} letterSpacing={"0.06em"}>Unlimited Discounted Doctor Consults </ListItem>
                <ListItem fontFamily={"Poppins"} fontSize={isMobile ? "8px":"10px"} fontWeight={400} letterSpacing={"0.06em"}>Ambulance Cost discount from market rate </ListItem>
                <ListItem fontFamily={"Poppins"} fontSize={isMobile ? "8px":"10px"} fontWeight={400} letterSpacing={"0.06em"}>Annual subscription cost (add 18% GST) </ListItem>
              </UnorderedList>
            </List>
            <Box
              mt={isMobile ? "24px":"13px"}
              height={"1px"}
              border="1px solid #0000001A"
            ></Box>
            <Box
              textAlign={"center"}
              alignContent={"center"}
              borderRadius="8px"
              border={"1px solid #00A7B5"}
              mt="5"
              height={"40px"}
              width="full"
              color="#00A7B5"
              fontFamily="Poppins"
              fontSize={ isMobile ? "12px" : isTab ? "16px" :"18px"}
              fontStyle="normal"
              fontWeight="500px"
              lineHeight="36px"
              backgroundColor={"#FFFFFF"}
              letterSpacing={"0.06em"}
              mb={isMobile ? "20px" : "1px"}
            >
              Rs.{Number(val.amount/100).toFixed(2)}/- per beneficiary
            </Box>
          </Box>
          <Box
            width={isMobile ? "300px" : isTab ? "400px" : "600px"}
            height={isMobile ? "100%" : isTab ? "100%" : "600px"}
            style={PlanStyle.overlayBoxStyle}
          >
          </Box>
          </Box>
        ))}

        <Box
          p={5}
          paddingBottom={"0px"}
          ml={isMobile ? " " : "3"}
          mx= {isTab ? "auto" : "3"}
          bgColor={"white"}
          boxShadow="sm"
          w={isMobile ? "full" : "394px"}
          height={isMobile ? "350px" : "387px"}
          style={PlanStyle.chooseNobenStyle}
          border={"1px dashed #00A7B580"}  
          borderRadius={"4px"} 
        >
          <Box mt="5">
            <Text style={{...PlanStyle.headingStyle,fontSize:isMobile ? "14px": "16px"}}>
              Choose No. Of Members
            </Text>
            <Box
              padding={"7px"}
              display={"flex"}
              flexDir={"row"}
              alignItems={"center"}
            >
              <svg
                xmlns="Images/users.svg"
                width= {isMobile ? "20px":"24"}
                height={isMobile ? "20px":"24"}
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21"
                  stroke="black"
                  stroke-opacity="0.4"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z"
                  stroke="black"
                  stroke-opacity="0.4"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M23 21.0009V19.0009C22.9993 18.1146 22.7044 17.2536 22.1614 16.5532C21.6184 15.8527 20.8581 15.3524 20 15.1309"
                  stroke="black"
                  stroke-opacity="0.4"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16 3.13086C16.8604 3.35116 17.623 3.85156 18.1676 4.55317C18.7122 5.25478 19.0078 6.11769 19.0078 7.00586C19.0078 7.89403 18.7122 8.75694 18.1676 9.45855C17.623 10.1602 16.8604 10.6606 16 10.8809"
                  stroke="black"
                  stroke-opacity="0.4"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <Box marginLeft={"15px"} display={"flex"} flexDir={"row"}>
                <IconButton
                  width={isMobile ? "40px":"45px"}
                  height={isMobile ? "40px":"48px"}
                  isDisabled={benCount <= 1 ? true : false}
                  onClick={decreamentBenCount}
                  style={PlanStyle.noOfBenInnerStyle}
                  aria-label="Decrease count"
                  icon={<MinusIcon />}
                />
                <Box
                  ml={"7px"}
                  mr={"7px"}
                  width={isMobile ? "54px":"62px"}
                  height={isMobile ? "40px":"48px"}
                  fontFamily={"Poppins"}
                  fontSize={isMobile ? "12px":"14px"}
                  fontWeight={400}
                  style={{...PlanStyle.noOfBenInnerStyle,backgroundColor:"#FFFFFF"}}
                >
                  {benCount < 10 ? "0"+benCount : benCount}
                </Box>
                <IconButton
                  width={isMobile ? "40px":"45px"}
                  height={isMobile ? "40px":"48px"}
                  onClick={incrementBenCount}
                  style={PlanStyle.noOfBenInnerStyle}
                  aria-label="Increase count"
                  icon={<AddIcon />}
                />
              </Box>
            </Box>
          </Box>
          <Box mt={isMobile ? "3":"6"}>
            <Text style={{...PlanStyle.headingStyle,fontSize:isMobile ?   "14px":"16px"}}> Total Amount</Text>
            <Box
              mt="12px"
              padding={"7px"}
              display={"flex"}
              flexDir={"row"}
              alignItems={"center"}
            >
              <Image
                src="https://developmentbucket.blob.core.windows.net/logos/rupee-logo.png"
                alt="Rupee logo"
              />

              <Box
                marginLeft={"11px"}
                borderRadius="4px"
                border="1px solid rgba(51, 51, 51, 0.20)"
                display="inline-flex"
                padding={isMobile ? "11px 45px 11px 16px" : "14px 53px 13px 16px"}
                alignItems="center"
                fontSize= {isMobile ? "12px":"16px"}
                fontWeight="500"
                lineHeight= {isMobile ? "18px":"21px"}
                letterSpacing="0.04em"
              >
                Rs. {(benCount * Number(selectedPlan?.amount) / 100).toFixed(2)}/-
              </Box>
            </Box>
            <Text
              fontSize="12px"
              fontWeight="500"
              lineHeight="36px"
              letterSpacing="0.06em"
              color= "#919598A3"
              marginLeft={"15px"}
            >
              Rs.{Number(selectedPlan?.amount) / 100}/- per beneficiary
            </Text>
          </Box>
          <Box mt={"16px"} height={"1px"} bgColor={"rgba(0, 0, 0, 0.10)"}></Box>
          <Box mt={"16px"} mb={isMobile ? "32px" : "13px"}>
            <Button
              background="#00A7B5"
              width={"full"}
              color="#FFF"
              textAlign="center"
              fontFamily="Poppins"
              fontSize={isMobile ? "12px":"16px" }
              fontStyle="normal"
              fontWeight="500"
              lineHeight="22.53px"
              onClick={HandlePayment}
              isLoading={isLoading}
              borderRadius={"8px"}
            >
              Pay Now
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
    </>
  );
};

export default PlanDetails;
